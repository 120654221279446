<template>
  <div class="brandStoryTile">
    <router-link
      class="link"
      :to="'/brandStory?id=' + brandStory.id"
      @click.native="setSelectedBrandStory"
    >
      <AssetDownload
        @click.native="setSelectedBrandStory"
        :class="height"
        :asset="brandStory.thumbnailAsset"
        :forceImage="brandStoryImage"
        :isStoryTile="true"
      ></AssetDownload>
    </router-link>
    <div class="middleCopy">
      <router-link
        class="link"
        :to="'/brandStory?id=' + brandStory.id"
        @click.native="setSelectedBrandStory"
      >
        <div class="title">{{brandStory.subject}}</div>
      </router-link>
    </div>
    <div class="bottomSection">
      <div class="postedBy">
        <div v-if="isPostedByUserNotAdmin">
          {{copy.postedBy}}
          <router-link
            @click.native="setProfileViewUser"
            :to="{path: 'profile', query: { id: brandStory.submittedByUser.id }}"
          >{{brandStory.submittedByUser.namePreferred}}</router-link>
        </div>
      </div>
      <div v-if="brandStory.brandStoryLikesCount > 0" class="bs-like">
        <font-awesome-icon :icon="['fal', 'heart']" class="icon" />
        {{brandStory.brandStoryLikesCount}}
      </div>
    </div>
  </div>
</template>

<script>
import copyBrandStoryTile from '../../../../data/brandStoryTitleCopy';
import AssetDownload from '../../../asset/AssetDownload.vue';

export default {
  name: 'BrandStoryTile',
  props: ['brandStory', 'givenHeightName', 'brandStoryImage'],
  components: {
    AssetDownload,
  },
  data() {
    return {
      copy: copyBrandStoryTile,
      downloadedImage: null,
    };
  },
  methods: {
    setSelectedBrandStory() {
      this.$store.dispatch(
        'brandStoryStore/setCurrentBrandStory',
        this.brandStory,
      );

      this.$analytics.trackEventBtnPageWithMetaInfo('BrandStoryTileClick',
        { brandStoryId: this.brandStory.id });
    },
    setProfileViewUser() {
      const route = this.$route;
      if (route.name !== 'profile' || route.query.id !== this.brandStory.submittedByUser.id) {
        this.$store.dispatch(
          'profileViewStore/setProfile',
          this.brandStory.submittedByUser,
        );
      }
    },
  },
  computed: {
    height() {
      if (this.givenHeightName) {
        return this.givenHeightName;
      }

      const min = 1;
      const max = 3;
      const random = Math.floor(Math.random() * (+max - +min)) + +min;
      switch (random) {
        case 1:
          return 'sizeFull';
        case 2:
          return 'sizeMedium';
        default:
          return 'sizeSmall';
      }
    },
    isPostedByUserNotAdmin() {
      let bIsNotAdmin = true;
      if (this.brandStory.submittedByUser) {
        if (this.brandStory.submittedByUser.role) {
          bIsNotAdmin = this.brandStory.submittedByUser.role.name !== 'ADMIN';
        }
      }
      return bIsNotAdmin;
    },
  },
};
</script>

<style lang="scss" scoped>
.brandStoryTile {
  border-radius: 10px;
  margin-bottom: 30px;
  box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
  color: $BrandStoryTileText;
  overflow: hidden;

  a {
    text-decoration: none;
    color: inherit;

    &:hover {
      color: $LincolnOrange;
    }
  }

  .sizeFull {
    height: 300px;
  }

  .sizeMedium {
    height: 200px;
  }

  .sizeSmall {
    height: 150px;
  }

  .middleCopy {
    padding: 10px 10px 0 10px;

    .title {
      @include font-size(1.125rem);
    }
  }

  .bottomSection {
    padding: 10px 10px 15px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    @include font-size(0.75rem);

    a {
      text-decoration: underline;
    }

    .postedBy {
      display: flex;
      flex: 1;
    }

    .bs-like {
      display: flex;
      align-items: center;

      .icon {
        margin-right: 5px;
      }
    }
  }
}
</style>
